<template>
  <div class="grade-appeal appeal section container">
    <div class="row pt-5">
      <div class="clearfix">

      <main id="main-content">
      <div class="col-sm-5 float-sm-end mb-3 ms-sm-4">

      <div class="basics card">
          <img src="https://images.prismic.io/csug/7984d768-31f5-4d66-a166-958ce6d2b310_appeals-grade-basics-cap.png" class="card-img-top d-none d-md-block" alt="">
        <div class="card-body px-4">


    <h2 class="card-title">Late Grade Appeal Basics</h2>

    <p>
      <strong>What You'll Need:</strong> A completed Late grade Appeal Form, with a valid appeal factor, submitted by the appeal deadline
    </p>

    <p>
      <strong>How to Start:</strong> Contact your Student Success Counselor, who will discuss the options, and make the correct form available in the Document Center, in the Student Portal
    </p>
    
    <p>
      <strong>Appeal Deadline:</strong> Within 90 days of the end of the term in question, including all supporting documentation
    </p>

    
    <p><strong>Appeal Factors:</strong></p>
    <ul>
      <li>Work submitted beyond the deadline due to an obstacle.</li>
      <li>Request to submit work beyond the deadline due to an obstacle.</li>
    </ul>

    <p>
      <strong>Decision Time Frame:</strong> 2 weeks by Program Director.
    </p>

    <p>
      <strong>Provost Appeal:</strong> Must appeal to Provost within 2 weeks after decision.
    </p>



    <a class="d-inline-flex align-items-center text-decoration-none ms-3 fs-5" href="https://portal.csuglobal.edu/portal/student#sidebar-advisor"><i class="bi bi-arrow-right-circle-fill fs-3 me-2"></i> Contact Your Student Success Counselor</a>


        </div><!--end card body -->

      </div><!--end card-->
      </div><!--end col-->



        <div class="header mb-4">
          <h1 class="display-2">Late Grade Appeal</h1>
        </div>
        
      <p class="lead">Students who seek to challenge a grade received on an assignment may file a grade appeal. Successful appeals will provide documentation and support that the instructor did not follow the grading rubric or that their work was graded with bias.</p>
       


    <h2>Grade Appeal Policy</h2>
    <p class="lead">
      Late grade appeals are available when students either have work submitted beyond the deadline or have a request to submit work beyond the deadline for grading.
    </p>

    <p>
      Prior to considering a grade appeal, students are expected to:
    </p>

    <ul>
      <li>
        Discuss course-related questions or concerns with their instructor directly so that the instructor has the opportunity to work with the student to seek resolution.
      </li>
      <li>
        Contact their student success counselor as soon as questions or concerns arise so that administration has an opportunity to help facilitate immediate resolution prior to the end of the course.
      </li>
      </ul>
        

      </main>
    </div><!--end clearfix-->
    </div><!--end row-->
    
  </div><!--End Container -->
<aside>
  <AdvisorCallToAction />
</aside>

</template>


<script>
// @ is an alias to /src
import AdvisorCallToAction from "@/components/AdvisorCallToAction.vue";

export default {
  name: "GradeAppeal",
  components: {
    AdvisorCallToAction,
  },
    mounted() {
    document.title = 'Grade Appeal | CSU Global Appeals Center';
        
    },
};
</script>




